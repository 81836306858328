@import "./day-picker.module.css";

.js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none;
  box-shadow: none;
}

#snap-midtrans {
  color-scheme: light;
}
