/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@-moz-document url-prefix() {
  .hideFirefox {
    appearance: none !important;
    display: none !important;
    -moz-appearance: none !important;
    visibility: hidden;
  }
}

@import "react-day-picker/dist/style.css";

.rdp {
  margin: 0;
}

.rdp-day {
  width: 100%;
  min-width: 40px;
  max-width: 100%;
}

.datePicker-container {
  margin: 0;
}

.datePicker-cell:hover {
  background-color: #333;
}

.datePicker-disabled {
  opacity: 0.2;
}

.datePicker-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datePicker-captionLabel {
  font-weight: 700;
  font-size: 1.15rem;
}
